<template>
    <v-expansion-panels v-model="panel">
        <v-expansion-panel>
            <v-expansion-panel-header class="card-title mt-1">
                Filters
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row>
                    <v-col cols="12" lg="3">
                        <v-text-field
                            v-model="name"
                            menu-props="auto"
                            label="Name"
                            hide-details
                            clearable
                            return-object
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-text-field
                            v-model="surname"
                            menu-props="auto"
                            label="Surname"
                            hide-details
                            clearable
                            return-object
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-autocomplete
                            v-model="team"
                            :items="teams"
                            item-text="name"
                            menu-props="auto"
                            label="Team"
                            hide-details
                            clearable
                            return-object
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-select
                            v-model="active"
                            :items="[true, false]"
                            label="Active"
                            clearable
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-select
                            v-model="doctor"
                            :items="[true, false]"
                            label="Doctor"
                            clearable
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-select
                            v-model="matchesRule"
                            :items="['noOfMatches1', 'noOfMatches2', 'noOfMatches3']"
                            label="MatchesRule"
                            clearable
                        >
                            <template slot="selection" slot-scope="data">
                                <!-- HTML that describe how select should render selected items -->
                                {{ `#MatchesRule${data.item.slice(-1)}` }}
                            </template>
                            <template slot="item" slot-scope="data">
                                <!-- HTML that describe how select should render items when the select is open -->
                                {{ `#MatchesRule${data.item.slice(-1)}` }}
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" lg="3" align="end">
                        <v-btn class="my-2 mr-2" dark small color="black" @click="clearFilters">
                            <v-icon dark>mdi-close</v-icon>Clear filters
                        </v-btn>
                    </v-col>
                    <v-col cols="12" lg="3" align="start">
                        <v-btn class="my-2 mr-2" dark small color="black" @click="applyFilters">
                            <v-icon dark>mdi-filter</v-icon>Apply filters
                        </v-btn>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import teamService from '@/services/team'
    export default {
        name: 'PlayerFilters',
        data: () => {
            return {
                name: null,
                surname: null,
                team: null,
                teams: [],
                active: null,
                doctor: null,
                matchesRule: null,
                panel: undefined,
                filters: null
            }
        },
        watch: {
            filters: {
                handler() {
                    this.setFilters()
                }
            }
        },
        created() {
            this.filters = this.$store.getters.getPlayerFilters
            this.fetchAllTeams()
        },
        methods: {
            setFilters() {
                this.filters = decodeURIComponent(this.filters)
                this.filters = this.filters.replace(/[|&;$%@<>()+=?]/g, '').replace(/filter/g, '')
                let query = JSON.parse(this.filters)
                for (const property in query) {
                    switch (property) {
                    case 'name':
                        this.name = query[property]
                        break
                    case 'surname':
                        this.surname = query[property]
                        break
                    case 'active':
                        this.active = query[property]
                        break
                    case 'doctor':
                        this.doctor = query[property]
                        break
                    case 'noOfMatches':
                        this.matchesRule = query[property]
                        break
                    case 'team':
                        console.log(query[property])
                        console.log(this.teams)
                        this.team = this.teams.find(team => team.id === parseInt(query[property]))
                        console.log(this.team)
                        break
                    default:
                        console.log('there is no such filter')
                    }
                }
            },
            fetchAllTeams() {
                teamService.getTeams().then(resp => {
                    this.teams = resp.data.content
                    this.setFilters()
                })
            },
            clearFilters() {
                this.name = null
                this.surname = null
                this.team = null
                this.active = null
                this.doctor = null
                this.matchesRule = null
                this.filters = null
                this.$emit('setFilters', null)
            },
            applyFilters() {
                let query = ''
                if (this.name) {
                    query += `"name":"%${this.name}%",`
                }
                if (this.surname) {
                    query += `"surname":"%${this.surname}%",`
                }
                if (this.active !== null) {
                    query += `"active":${this.active},`
                }
                if (this.doctor !== null) {
                    query += `"doctorCheck":${this.doctor},`
                }
                if (this.matchesRule) {
                    query += `"noOfMatches":"${this.matchesRule}",`
                }
                if (this.team) {
                    query += `"team":"${this.team.id}",`
                }
                this.filters = `?filter=${encodeURIComponent('{' + query.slice(0, -1) + '}')}`
                this.$emit('setFilters', this.filters)
            }
        }
    }
</script>
