<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col cols="12" lg="4">
                        Players
                    </v-col>
                    <v-col cols="12">
                        <player-filters @setFilters="setFilters"/>
                    </v-col>
                    <v-col cols="12" align="end">
                        <v-btn class="my-2 mr-2" dark small color="black" @click="createPlayer">
                            <v-icon dark>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>
        <array-view
            :items="players"
            :headers="displayInfo"
            :total-pages="totalPages"
            :page="page"
            :size="size"
            @handleClick="handleClick"
            @paginationChange="paginationChange"
            @onDelete="onDelete"
        />
    </v-container>
</template>

<script>
    import playerService from '@/services/player'
    import ArrayView from '@/components/ArrayView'
    import PlayerFilters from '@/components/PlayerFilters'

    export default {
        name: 'Player',
        components: { ArrayView, PlayerFilters },
        data: () => {
            return {
                successfullImport: null,
                players: [],
                displayInfo: [
                    {
                        text: 'Id',
                        value: 'id'
                    },
                    {
                        text: 'Active',
                        value: 'active'
                    },
                    {
                        text: 'Name',
                        value: 'name'
                    },
                    {
                        text: 'Surname',
                        value: 'surname'
                    },
                    {
                        text: 'Doctor Check',
                        value: 'doctorCheck'
                    },
                    {
                        text: '#MatchesRule',
                        value: 'noOfMatches'
                    },
                    {
                        text: 'Jersey',
                        value: 'jersey'
                    },
                    {
                        text: 'Position',
                        value: 'position'
                    },
                    {
                        text: 'Team',
                        value: 'team',
                        subLevel: 'name'
                    }
                ],
                showImportDialog: false,
                size: 5,
                totalPages: 0,
                page: 1,
                loading: false,
                filters: null
            }
        },
        watch: {
            filters: {
                handler() {
                    this.fetchFilteredPlayers()
                }
            }
        },
        created() {
            this.filters = this.$store.getters.getPlayerFilters
            this.page = this.$store.getters.getPage
            this.fetchFilteredPlayers()
        },
        methods: {
            paginationChange(page, size) {
                this.size = size
                this.page = page
                this.$store.dispatch('setPage', page)
                this.fetchFilteredPlayers()
            },
            handleClick(action, item) {
                this.$router.push(`/players/${item.id}/${action}`)
            },
            createPlayer() {
                this.$router.push(`/players/create`)
            },
            onDelete(item, page) {
                playerService.deletePlayer(item.id).then(() => {
                    this.page = page
                    this.fetchFilteredPlayers()
                })
            },
            setFilters(filters) {
                this.filters = filters
                this.$store.dispatch('setPlayerFilters', this.filters)
            },
            fetchFilteredPlayers() {
                this.loading = true
                playerService.getFilteredPlayers(this.filters, this.page, this.size).then(resp => {
                    this.players = resp.data.content
                    this.totalPages = resp.data.totalPages
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>
.card-title {
    display: flex;
}

.v-list-item {
    min-height: 30px !important;
}

.v-card {
    min-width: 250px;
    position: relative;
}

.header-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
}

.header-buttons {
    align-self: center;
}

.edit-button:hover {
    background-color: whitesmoke;
    color: white;
}

.screen-card {
    padding: 4%;
}

.overflow-text {
    max-width: 79%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pagination {
    justify-content: center;
}
</style>
